<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="p-6 mini:py-8 mini:px-10 rounded-lg overflow-hidden relative border"
  >
    <img src="/spectrum.jpg" class="spectrum" />
    <div class="grid gap-8 grid-cols-1 mini:grid-cols-2 items-center relative">
      <div>
        <h4 class="h4 my-0">Get inspired</h4>
        <p class="text-md my-0">
          Search fragrances by mood, event, or concept.
        </p>
      </div>
      <div>
        <form class="flex gap-2" @submit.prevent="searchProducts">
          <div class="relative flex-1">
            <div
              class="absolute bottom-0 right-0 m-[-1px] bg-gradient-to-r top-0 left-0 from-blue-200/50 via-yellow-200 to-orange-200 rounded-lg opacity-75"
            />
            <div class="relative">
              <Input
                ref="inputElement"
                :placeholder="search || pauseAnimation ? '' : word"
                class="bg-white shadow pl-11"
                v-model="search"
                @focus="inputFocus"
                @blur="inputBlur"
              />
              <SvgIcon
                icon="search-magic"
                class="absolute block w-6 h-6 top-2.5 left-2.5 text-blue-text"
              />
            </div>
          </div>

          <Button
            :loading="loading"
            v-if="search"
            color="blue"
            size="medium"
            class="rounded-lg"
          >
            Go
          </Button>
        </form>
        <div
          class="mt-4 bg-yellow-tint text-yellow-text border-yellow-text border-2 border-solid rounded-lg px-4 py-2 inline-block"
          v-if="invalidError"
        >
          {{ invalidError }}
        </div>
      </div>
    </div>
    <div v-if="results.length" class="pt-4">
      <div class="relative">
        <div class="mini:columns-2 sm:columns-3 gap-8">
          <nuxt-link
            v-for="product in results"
            :key="product.id"
            :to="product.urlPath"
            @click="trackResultClick(product)"
            class="flex gap-6 items-center text-onyx-500 py-4"
          >
            <div class="w-13 h-13 block rounded-lg overflow-hidden mt-1">
              <ProductPicture
                html-role="none"
                :image="{
                  src: product.imageUrl,
                  alt: product.imageAlt,
                  srcset: product.imageSrcset,
                }"
                sizes="64px"
              />
            </div>
            <div class="flex-1">
              <h6 class="h6 my-0 truncate">{{ product.name }}</h6>
              <div
                v-html="product.description.replace(/<[^>]*>?/gm, '')"
                class="line-clamp-2"
              />
            </div>
          </nuxt-link>
        </div>
      </div>
      <div class="text-center pt-4">
        <Button size="small" layout="silent" color="blue" @click="results = []">
          Done
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Filter } from "bad-words"
  import { deserialize } from "@alchemy_cms/json_api"
  import words from "~/lib/profanityWords"
  const { handleError } = useErrorHandling()

  const filter = new Filter()
  filter.addWords(...words)
  const { api } = useApi()
  const gtm = useGtm()

  const loading = ref(false)

  const search = ref("")
  const results: Ref<SolidusProduct[]> = ref([])
  const invalidError = ref<string | null>(null)

  const SEARCH_SUGGESTIONS = [
    "A peaceful garden retreat",
    "The start of a new day",
    "Quiet library moments",
    "Feeling of exploration",
    "Fresh beginnings",
    "Calm and tranquility",
    "Sunlit afternoon",
    "Magical evening",
    "Endless possibilities",
    "Warm nostalgia",
    "Gentle rain rhythm",
    "Calm in chaos",
    "Secluded tranquility",
    "Sense of adventure",
    "Cozy comfort",
    "Nature's harmony",
    "Timeless romance",
    "Quiet reprieve",
    "Refreshing breeze",
    "Warm memories",
  ]

  const options = {
    words: SEARCH_SUGGESTIONS,
    min: 10,
    max: 80,
    wordDelay: 4000,
    eraseDelay: 500,
  }

  const pauseAnimation = ref(false)
  const inputBlur = () => {
    setTimeout(() => {
      pauseAnimation.value = false
    }, 300)
  }
  const inputFocus = () => {
    pauseAnimation.value = true
  }

  const { word } = useTypewriter(options)

  const searchProducts = async () => {
    invalidError.value = null
    const isProfane = filter.isProfane(search.value)
    if (isProfane) {
      gtm?.trackEvent({
        event: "ScentGenie Search",
        search_term: search.value,
        isProfane,
      })
      invalidError.value = "Please enter a valid search term."

      return
    }

    loading.value = true

    results.value = await api<SolidusProduct[]>(
      "/jsonapi/recommended_fragrances/",
      {
        params: {
          prompt: search.value,
        },
      },
    )
      .catch(handleError)
      .then(deserialize)

    gtm?.trackEvent({
      event: "ScentGenie Search",
      search_term: search.value,
      search_results: results.value.map((product) => ({
        name: product.name,
        id: product.id,
        url: product.urlPath,
      })),
      isProfane,
    })

    loading.value = false
  }

  const trackResultClick = (product: SolidusProduct) => {
    gtm?.trackEvent({
      event: "ScentGenie Result Click",
      product: {
        name: product.name,
        id: product.id,
        url: product.urlPath,
      },
    })
  }
</script>

<style>
  .spectrum {
    animation: spectrum 30s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    min-width: 200%;
    min-height: 200%;
    aspect-ratio: 1;
    display: block;
    opacity: 15%;
  }
  @keyframes spectrum {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
</style>
